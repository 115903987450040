<template>
  <div class="">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg("ID") }}</label>
            <b-form-input
                v-model="filter.like.id"
                class="d-inline-block mr-1"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg("Client") }}</label>
            <b-form-input
                v-model="filter.like.clientFullName"
                class="d-inline-block mr-1"
            />
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg('Status') }}</label>
            <v-select
                v-model="filter.in.status"
                :options="depositStatus"
                class="w-100"
                label="text"
                :reduce="(status) => status.value"
                multiple
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Deposits') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  @option:selected="refresh"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <span class="mr-1"></span>
                <b-button disabled variant="primary" @click="onNewDeposit(0)">
                  <span class="text-nowrap">{{ msg('New Deposit') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
        <!--Table-->
        <b-table
            ref="deposit-table"
            striped
            hover
            responsive
            v-bind:items="deposits"
            v-bind:fields="fields"
            :busy="loading"
            @sort-changed="onSort"
            @row-clicked="onRowClick"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>
          <!--          <template #cell(clientId)="data">
                      <div v-if="clients[data.value] != null">
                        <b-badge variant="secondary" @click="onClientSelect(clients[data.value].id)">{{
                            clients[data.value].firstname
                          }}
                          {{ clients[data.value].lastname }}
                        </b-badge>
                      </div>
                      <div v-else>
                        <b-badge variant="warning">{{ msg('Anonymous') }}</b-badge>
                      </div>
                    </template>-->

          <template #cell(contractId)="data">
            <b-link @click="goToContract(data.value)">{{ msg('To the contract') }}</b-link>
          </template>

          <template #cell(type)="data">
            <span style="white-space: nowrap">{{
                data.value === 'AUTOMATIC' ? msg('Automatic (Scheduler)') : msg('User')
              }}</span>
          </template>
          <template #cell(clientFullName)="data">
            <b-badge style="white-space: nowrap">{{ data.value }}</b-badge>
          </template>

          <template #cell(depositAmount)="data">
            <span>{{ convertToEURO(data.value) }}</span>
          </template>
          <template #cell(creationDate)="data">
            <span>{{ $moment(data.value, 'YYYY-MM-DD').format("DD.MM.YYYY") }}</span>
          </template>

          <template #cell(checkInDate)="data">
            <span>{{ $moment(data.value, 'YYYY-MM-DD').format("DD.MM.YYYY") }}</span>
          </template>

          <template #cell(checkOutDate)="data">
              <span v-if="data.value">
                {{ $moment(data.value, 'YYYY-MM-DD').format("DD.MM.YYYY") }}
            <span style="white-space: nowrap;color: red" v-if="$moment().isAfter(data.value)">
                ({{ $moment().diff($moment(data.value, 'YYYY-MM-DD'), 'days') }} {{msg('days ago')}})
    </span>
  </span>
          </template>

          <template #cell(id)="data">
            <b-button variant="outline-transparent" size="sm" @click="openSideBar(data.item.contractId)">
              <b-img src="../../../assets/images/icons/eye.svg" alt="sms" style="width: 30px; height: 30px;"/>
            </b-button>
          </template>

          <template #cell(depositedDate)="data">
            <span v-if="data.value!=null && data.value!==''">{{
                $moment(data.value, 'YYYY-MM-DD').format("DD.MM.YYYY")
              }}</span>
            <span style="color: darkred;font-weight: bold" v-else>{{ msg('Not deposited yet') }}</span>
          </template>
          <template #cell(refundedDate)="data">
            <span v-if="data.value!=null && data.value!==''">{{
                $moment(data.value, 'YYYY-MM-DD').format("DD.MM.YYYY")
              }}</span>
            <span style="color: darkred;font-weight: bold" v-else>{{ msg('Not refunded yet') }}</span></template>


          <template #cell(realEstateId)="data">
            <b-link style="font-weight: bold" v-if="data.value!=null && data.value!==''"
                    @click="routeToProperty(data.value)" variant="danger">
              {{ realEstates[data.value].name }}
            </b-link>
          </template>

          <template #cell(status)="data" class="p-0">
            <b-badge :variant="statusVariants[data.value]">{{ msg(data.value) }}</b-badge>
          </template>


          <template #cell(actions)="row">
            <div class="d-flex flex-nowrap">
              <!-- Delete Button -->
              <b-button
                  size="sm"
                  :disabled="row.item.status === 'CANCELLED' || row.item.status === 'DEPOSITED' || row.item.status === 'REFUNDED'"
                  @click="openConfirmationModal('delete', row.item)"
                  class="btn btn-danger mr-1"
                  v-b-tooltip.hover
                  :title="msg('Cancel')"
              >
                <i class="fa fa-cancel" aria-hidden="true"></i>
              </b-button>

              <!-- Pause Button -->
              <b-button
                  size="sm"
                  :disabled="row.item.status === 'CANCELLED' || row.item.status === 'DEPOSITED' || row.item.status === 'REFUNDED'"
                  v-if="!row.item.paused"
                  @click="openConfirmationModal('pause', row.item)"
                  class="btn btn-warning mr-1"
                  v-b-tooltip.hover
                  :title="msg('Pause')"
              >
                <i class="fa fa-pause" aria-hidden="true"></i>
              </b-button>

              <!-- Resume Button -->
              <b-button
                  size="sm"
                  v-if="row.item.paused"
                  :disabled="row.item.status === 'CANCELLED' || row.item.status === 'DEPOSITED' || row.item.status === 'REFUNDED'"
                  @click="openConfirmationModal('resume', row.item)"
                  class="btn btn-success mr-1"
                  v-b-tooltip.hover
                  :title="msg('Continue')"
              >
                <i class="fa fa-play" aria-hidden="true"></i>
              </b-button>

              <!-- Charge Button -->
              <b-button
                  size="sm"
                  :disabled="row.item.wasDeposited === true || row.item.status === 'CANCELLED' || row.item.wasRefunded === true|| checkOutDueDate(row.item)"
                  @click="openConfirmationModal('charge', row.item)"
                  class="btn btn-success mr-1"
                  v-b-tooltip.hover
                  :title="msg('Charge')"
              >
                <i class="fa fa-bolt" aria-hidden="true"></i>
              </b-button>

              <!-- Refund Button -->
              <b-button
                  size="sm"
                  :disabled="row.item.wasDeposited === false || row.item.wasRefunded === true || row.item.status === 'CANCELLED'"
                  @click="openConfirmationModal('refund', row.item)"
                  class="btn btn-info mr-1"
                  v-b-tooltip.hover
                  :title="msg('Refund')"
              >
                <i class="fa fa-dollar-sign" aria-hidden="true"></i>
              </b-button>
            </div>
          </template>

        </b-table>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
        <b-modal
            v-model="showConfirmationModal"
            :title="msg('Confirm Action')"
            @cancel="closeModal"
            centered
            :cancel-title="msg('Cancel')"
            ok-variant="success"
            cancel-variant="danger"
            @ok="confirmAction"
        >
          <div>
            <p>{{ modalMessage }}</p>
            <b-input
                v-if="modalEvent === 'charge'"
                v-model="chargeAmount"
                type="number"
                :value="chargeAmount"
                min="0"
            ></b-input>
          </div>
        </b-modal>
      </b-card-body>
    </b-card>
    <ContractRentSidebar ref="contractRentSidebar"/>

  </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ContractRentSidebar from "@/components/sidebar/ContractRent/ContractRentSidebar.vue";

export default {
  name: 'Deposits',
  components: {ContractRentSidebar},
  data() {
    return {
      loading: true,
      amount: 10,
      deposits: [],
      fields: [],
      selected: null,
      realEstates: {},
      showConfirmationModal: false,
      modalEvent: '', // To store the event type (e.g., 'charge', 'delete', etc.)
      modalMessage: '', // The dynamic message based on the event type
      chargeAmount: 0, // The amount to charge, for the 'charge' event
      selectedItem: null, // The item being acted upon
      filter: {
        like: {search: ''},
        eq: {},
        lte: {},
        gte: {},
        in: {status: []}
      },
      sort: {
        by: 'creationDate',
        desc: false,
        direction: 'asc'
      },
      statusVariants: {

        "NEW": "info",
        "DEPOSITED": "success",
        "REFUNDED": "primary",
        "CANCELLED": "danger",
        "PAUSED": "warning",
        "FAILED": "danger",
      },


      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      dialogs: {newDeposit: false, updateDeposit: false},
      //Listeners
      listeners: {
        refresh: () => {
        }
      }

    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
    file: {
      handler() {
      }
    }
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('translator', ['msg']),
    ...mapGetters('deposit', ['allDepositStatus']),

    depositStatus() {
      let $this = this;
      return this.allDepositStatus.map(status => ({
        value: status.value,
        text: $this.msg(status.text)
      }))
    },
  },
  created() {
    let $this = this;
    this.setFields();
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
  },
  methods: {
    ...mapActions('deposit', ['getAllDeposits', 'copySuccess', 'updateDeposit', 'handleDepositEvent']),
    ...mapActions('clients', ['getClients']),
    openSideBar(id) {
      this.$refs.contractRentSidebar.showContract(id)
    },
    checkOutDueDate(item) {
      return item.checkOutDate!=null && this.$moment().isAfter(item.checkOutDate)
    },
    openConfirmationModal(event, item) {
      // Set the event type and selected item
      this.modalEvent = event;
      this.selectedItem = item;
      // Dynamically set the message based on the event
      if (event === 'delete') {
        this.modalMessage = this.msg('Are you sure you want to delete this deposit?');
      } else if (event === 'pause') {
        this.modalMessage = this.msg('Are you sure you want to pause this deposit?');
      } else if (event === 'resume') {
        this.modalMessage = this.msg('Are you sure you want to resume this deposit?');
      } else if (event === 'charge') {
        this.modalMessage = this.msg('Please confirm the charge amount.');
        this.chargeAmount = item.depositAmount
      } else if (event === 'refund') {
        this.modalMessage = this.msg('Are you sure you want to refund this deposit?');
      }

      // Show the confirmation modal
      this.showConfirmationModal = true;
    },
    closeModal() {
      // Close the confirmation modal
      this.showConfirmationModal = false;
    },
    confirmAction() {
      // Call the actual function based on the event
      if (this.modalEvent === 'delete') {
        this.handleEvent('delete', this.selectedItem);
      } else if (this.modalEvent === 'pause') {
        this.handleEvent('pause', this.selectedItem);
      } else if (this.modalEvent === 'resume') {
        this.handleEvent('resume', this.selectedItem);
      } else if (this.modalEvent === 'charge') {
        // Pass the charge amount to the handleEvent function
        this.handleEvent('charge', this.selectedItem, this.chargeAmount);
      } else if (this.modalEvent === 'refund') {
        this.handleEvent('refund', this.selectedItem);
      }

      // Close the modal after action
      this.closeModal();
    },
    handleEvent(event, item, amount = null) {
      let $this = this;
      console.log('Handling event:', event, 'amount is :', amount);
      this.handleDepositEvent({event: event, id: item.id, amount: amount}).then(() => $this.refresh())
      // You can integrate with your backend or perform other logic here
    },

    update(deposit) {
      let $this = this;
      this.updateDeposit({deposit: deposit}).then(() => $this.refresh())

    },
    goToContract(id) {
      window.open(`/real_estate/contracts/rent/${id}`, '_blank');
    },
    onClientSelect(clientId) {
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('crm::client::select', clientId)
    },
    routeToProperty(propertyId) {
      window.open(`/real_estate/edit/${propertyId}`, '_blank');
    },
    onCopy: function (e) {
      this.copySuccess(e.text)
    },
    onError: function (e) {
      alert('Failed to copy the text to the clipboard')
    },

    setFields() {
      let $this = this;

      this.fields = [

        {
          key: 'status',
          label: $this.msg('status'),
          sortable: true
        },
        {
          key: 'id',
          label: 'View',
          sortable: true
        },
        {
          key: 'realEstateId',
          label: $this.msg('realEstate'),
          sortable: true
        }, {
          key: 'contractId',
          label: $this.msg('contract'),
          sortable: true
        },
        {
          key: 'clientFullName',
          label: $this.msg('client'),
          sortable: true
        },
        {
          key: 'depositAmount',
          label: $this.msg('amount'),
          sortable: true
        },
        {
          key: 'checkInDate',
          label: $this.msg('Check in'),
          sortable: true
        }, {
          key: 'checkOutDate',
          label: $this.msg('Check out'),
          sortable: true
        }, {
          key: 'creationDate',
          label: $this.msg('Creation date'),
          sortable: true
        },
        {
          key: 'depositedDate',
          label: $this.msg('Deposited date'),
          sortable: true
        },
        {
          key: 'refundedDate',
          label: $this.msg('Refunded date'),
          sortable: true
        },

        {
          key: 'type',
          label: $this.msg('CreatedBy'),
          sortable: true
        },

        {
          key: 'actions',
          label: $this.msg('Actions'),
          sortable: true
        },


      ];
    },

    copyId(id) {
      console.log("the id copied was: ", id)
    },

    refresh() {
      let $this = this
      this.loading = true;
      this.sort = {
        by: 'creationDate',
        desc: true,
        direction: 'desc'
      },
          this.getAllDeposits({
            page: $this.page.currentPage,
            amount: $this.page.amount,
            filter: $this.filter,
            sortcolumn: $this.sort.by,
            sortby: $this.sort.direction,
          }).then(this.onDepositsRetrieve, (error) => $this.error = error)

    },
    onDepositsRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.deposits = data["Deposits"];
      this.realEstates = data["RealEstates"];
      this.retrieveClients(this.deposits);
    },
    retrieveClients(deposits) {

      let $this = this
      let ids = deposits.map((deposit) => deposit.clientId)
      this.getClients({
        page: 1,
        amountPerPage: -1,
        filter: {ids}
      }).then((clientData) => {


        $this.clients = clientData.clients.toObject('id')
        $this.loading = false
      })
    },
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    onNewDeposit(id) {

      this.dialogs.newDeposit = true

    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(deposit) {
      this.selected = deposit.id;
      this.dialogs.updateDeposit = true
    },

    onSubmitted(server) {
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.newDeposit = false;
      this.dialogs.updateDeposit = false;
    },

  }
}
</script>

<style>

</style>
